import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getFormList(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get(`formShow/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getFormStatistics(ctx, id) {
        return new Promise((resolve, reject) => {
          axiosIns
            //.get("user", { params: queryParams })
            .get(`formStatistics/${id}`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },

      getFormSections
      (ctx, id) {
        return new Promise((resolve, reject) => {
          axiosIns
            //.get("user", { params: queryParams })
            .get(`sectionList/${id}`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },

      


  },
};

